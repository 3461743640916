define("@additive-apps/ui/components/ui-application-navigation-drawer", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "ember-arg-types", "prop-types", "@additive-apps/ui/utils/constants"], function (_exports, _component, _templateFactory, _component2, _object, _service, _emberArgTypes, _propTypes, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiNavigationDrawer
    @class="ui-application-navigation-drawer flex flex-column"
    @onClose={{this.closeSubDrawers}}
    as |navDrawer|
  >
    {{yield
      (hash
        accountSwitch=(component
          "ui-application-navigation-drawer/account-switch" navDrawer=navDrawer
        )
      )
    }}
  
    <div class="flex flex-column flex-grow-1 oauto">
      <div class="mv1">
        {{yield
          (hash
            menuItem=(component
              "ui-application-navigation-drawer/menu-item" onClick=this.closeSubDrawers
            )
          )
        }}
      </div>
  
      <div class="flex-grow-1"></div>
      {{yield
        (hash appsList=(component "ui-application-navigation-drawer/apps-list" navDrawer=navDrawer user=this.user))
      }}
    </div>
  
    {{yield (hash userButton=(component "ui-application-navigation-drawer/user-button"))}}
  </UiNavigationDrawer>
  */
  {
    "id": "XtMQs630",
    "block": "[[[8,[39,0],null,[[\"@class\",\"@onClose\"],[\"ui-application-navigation-drawer flex flex-column\",[30,0,[\"closeSubDrawers\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,2,[[28,[37,2],null,[[\"accountSwitch\"],[[50,\"ui-application-navigation-drawer/account-switch\",0,null,[[\"navDrawer\"],[[30,1]]]]]]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"flex flex-column flex-grow-1 oauto\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"mv1\"],[12],[1,\"\\n      \"],[18,2,[[28,[37,2],null,[[\"menuItem\"],[[50,\"ui-application-navigation-drawer/menu-item\",0,null,[[\"onClick\"],[[30,0,[\"closeSubDrawers\"]]]]]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"flex-grow-1\"],[12],[13],[1,\"\\n    \"],[18,2,[[28,[37,2],null,[[\"appsList\"],[[50,\"ui-application-navigation-drawer/apps-list\",0,null,[[\"navDrawer\",\"user\"],[[30,1],[30,0,[\"user\"]]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[18,2,[[28,[37,2],null,[[\"userButton\"],[[50,\"ui-application-navigation-drawer/user-button\",0,null,null]]]]]],[1,\"\\n\"]],[1]]]]]],[\"navDrawer\",\"&default\"],false,[\"ui-navigation-drawer\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@additive-apps/ui/components/ui-application-navigation-drawer.hbs",
    "isStrictMode": false
  });
  let UiApplicationNavigationDrawer = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _class = class UiApplicationNavigationDrawer extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiState", _descriptor, this);
      /**
       * the user object
       *
       * @argument user
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "user", _descriptor2, this);
      _defineProperty(this, "_navDrawerStateKey", 'ui-navigation-drawer');
      /**
       * the state keys of the sub drawers
       *
       * @property _subDrawerStateKeys
       * @type {Array}
       */
      _defineProperty(this, "_subDrawerStateKeys", [_constants.uiApplicationNavigationDrawerAccountSwitchStateKey, _constants.uiApplicationNavigationDrawerAppsListStateKey]);
    }
    closeSubDrawers() {
      [this._navDrawerStateKey, ...this._subDrawerStateKeys].forEach(key => {
        var _this$uiState$getStat;
        if ((_this$uiState$getStat = this.uiState.getState(key)) !== null && _this$uiState$getStat !== void 0 && _this$uiState$getStat.isOpen) {
          this.uiState.getState(key).close();
        }
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiState", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "closeSubDrawers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeSubDrawers"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiApplicationNavigationDrawer);
});