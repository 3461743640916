define("ember-svg-jar/inlined/rocket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.25 7.083a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.117 2.535a.39.39 0 00-.234 0A5.701 5.701 0 006.001 9.07l.535 2.727-1.943 1.943a.25.25 0 00.177.427h10.46a.25.25 0 00.176-.427l-1.942-1.942L14 9.07a5.701 5.701 0 00-3.882-6.535zm1.854 10.382l.8-4.088A4.452 4.452 0 0010 3.818a4.452 4.452 0 00-2.772 5.011l.8 4.088h3.943z\"/><path d=\"M8.8 15a.25.25 0 00-.208.389l1.2 1.8a.25.25 0 00.416 0l1.2-1.8a.25.25 0 00-.209-.389H8.8z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});